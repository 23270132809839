import React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from 'src/components/layouts/default';
import BlockUploadForm from 'src/components/blocks/blockUploadForm.js';
import * as classes from './upload.module.scss';

export const query = graphql`
  query {
    datoCmsStart {
      uploadTitle
      uploadCompleteContent {
        blocks
        links
        value
      }
    }
  }
`;

const UploadPage = ({ data, location }) => (
  <Layout location={location} pageTitle={data.datoCmsStart.uploadTitle}>
    <div className={classes.body}>
      <BlockUploadForm
        onComplete={() => navigate(
          '/complete',
          {
            state: {
              pageTitle: data.datoCmsStart.uploadTitle,
              body: data.datoCmsStart.uploadCompleteContent,
            },
          }
        )}
      />
    </div>
  </Layout>
);

export default UploadPage;
